import React from "react";
import { useRef } from "react";
import emailjs from "@emailjs/browser";

const Carrer = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    alert("Form Submitted Successfully.");
    emailjs
      .sendForm(
        "service_x0qp7zc",

        "template_qv0m4u4",

        form.current,
        "tmTWW-hXcDhbB5j6e"
      )

      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <>
      <div class="mt-5 pt-5 ">
        <div class="container text-center">
          <img src="img/Carrer.jpg" class="img-fluid  sm:w-100%" alt="" />
        </div>
      </div>
      <div class="container pt-5">
        <h1 class="fw-bold">Be Yourself.</h1>
        <p class="fw-bold text-primary pt-2 fs-4">Apply Today</p>
        <p class="fs-5 py-3">
          We invite you to be part of team of thinkers and doers. Work at
          DigiOpen Technologies, <br />
          where you along with top business and technology professionals are
          always up to something new.
        </p>
      </div>
      <form
        ref={form}
        onSubmit={sendEmail}
        enctype="multipart/form-data"
        method="post"
        className=" container shadow"
      >
        <div class="col-md-8 pt-3 ">
          <label for="name">Your Name</label>
          <input
            type="text"
            name="name"
            class="form-control"
            id="name"
            placeholder="Enter your name"
            required
          />
        </div>
        <div class="form-group col-md-8 my-3">
          <label for="email">Your Email</label>
          <input
            type="text"
            name="email"
            class="form-control"
            id="email"
            placeholder="Enter your Email"
            required
          />
        </div>
        <div class="form-group col-md-8 my-3">
          <label for="exampleFormControlInput1">Mobile Number</label>
          <input
            type="text"
            name="mobile"
            class="form-control"
            id="mobile"
            placeholder="Enter your Number"
            required
          />
        </div>
        <div class="form-group col-md-8 my-3">
          <label for="exampleFormControlInput1">
            Position That suits your Experience
          </label>
          <input
            type="text"
            name="position"
            class="form-control"
            id="position"
            placeholder="Enter your position that suits your experience"
            required
          />
        </div>
        <div class="form-group col-md-8 my-3 ">
          <label for="exampleFormControlTextarea1">
            More Info About Yourself
          </label>
          <textarea
            class="form-control"
            name="yourself"
            id="yourself"
            rows="3"
            required
          ></textarea>
        </div>
        {/* <div class="form-group col-md-8">
          <label for="exampleFormControlFile1">Upload Your CV</label>
          <input
            type="file"
            name="my_file"
            class="form-control-file"
            id="my_file"
          />
        </div> */}
        <div class="form-group col-md-8 pb-3">
          <button class="btn btn-primary">Apply</button>
        </div>
      </form>
    </>
  );
};

export default Carrer;
