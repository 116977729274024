import React from "react";
import { Link } from "react-router-dom";

const BusinessConsulting = () => {
  return (
    <>
      <div className="container">
        <h1 className="pt-5 mt-5 text-danger">Business Consulting</h1>
        <div className="row">
          <div className="col-md-6 mt-5">
            <nav>
              <ol class="breadcrumb ">
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Business Consulting
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-md-6">
            <img
              src="img/servicebusiness.jpg"
              alt="services Business"
              width="100%"
              className="img-fluid"
            />
          </div>
        </div>
        <h1 className="text-primary my-5 font-weight-bold">Services</h1>
        <div className="row">
          <div className="col-md-6">
            <img
              src="img/serviceprocessassessment.jpg"
              width="90%"
              height="60%"
              className="shadow"
            />
          </div>
          <div className="col-md-6">
            <h3 className="font-weight-bold text-primary">
              Process Assessment
            </h3>
            <p className="fs-6">
              DigiOpen Technologies consultants working with the leadership team
              to understand the key area of focus, the goals to be met and
              challenges to be overcome. They then work with organization
              representatives to identify root cause of the issues and to
              recommend solutions to address these goals. In executing these
              engagements, the consultant’s facilitation techniques for
              interviews/workshops with customer teams. Solutions in many cases
              come from within the organization, when they are supported with
              structured analysis approaches Digiopen also helps in implementing
              the changes identified, especially when these are related to
              system gaps/requirements. In other cases, the team would set a
              governance/tracking model for execution and would optionally
              provide review capabilities
            </p>
          </div>
        </div>
        <div className="row pt-3">
          <div className="col-md-6 ">
            <img
              src="img/servicetechnology.jpg"
              width="90%"
              height="70%"
              className="shadow"
            />
          </div>
          <div className="col-md-6">
            <h3 className="font-weight-bold text-primary">
              Defining Technology Roadmap
            </h3>
            <p className=" fs-6">
              DigiOpen works with companies to help business leaders define
              technology/system plans that align and support the achievement of
              their business goals.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BusinessConsulting;
