import React from "react";

const Footer = () => {
  return (
    <>
      <div className="container mt-5">
        <h3></h3>
        <marquee
          width="100%"
          direction="right"
          height="100px"
          className="text-danger fs-4"
        >
          <p className="text-primary fs-4">
            Get in touch with us to digitalise your business at :
            <span className="text-danger"> info@digiopentechnologies.com</span>
          </p>
        </marquee>
      </div>
      <div class="text-center text-white p-4 bg-dark">
        © 2021 Copyright:
        <a class="text-reset fw-bold"> DigiOpen Technologies</a>
      </div>
    </>
  );
};

export default Footer;
