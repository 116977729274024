import React from "react";

const Industries = () => {
  return (
    <>
      <div className="container mt-5 pt-5">
        <div className="row ">
          <div className="col-md-3 text-center p-3 border border-2">
            <img src="img/logo/Bharathi.jpeg" width="50%" />
          </div>
          <div className="col-md-3 p-3 text-center border border-2">
            <img src="img/logo/chintamani.jpeg" width="40%" />
          </div>
          <div className="col-md-3 p-3 text-center border border-2">
            <img src="img/logo/gubbaCold.jpg" width="50%" />
          </div>
          <div className="col-md-3 p-3 text-center border border-2">
            <img src="img/logo/gubbainfra.png" width="50%" />
          </div>
        </div>
        <div className="row ">
          <div className="col-md-3 text-center  p-3 border border-2">
            <img
              src="img/logo/Impression.png"
              className=" ml-auto mr-auto display-block "
              width="80%"
            />
          </div>
          <div className="col-md-3 text-center p-3 border border-2">
            <img src="img/logo/murr.png" width="80%" />
          </div>
          <div className="col-md-3 text-center p-3 border border-2">
            <img src="img/logo/neotric.jpg" width="80%" />
          </div>
          <div className="col-md-3 text-center p-3 border border-2">
            <img src="img/logo/opel.jpg" width="50%" />
          </div>
        </div>
        <div className="row ">
          <div className="col-md-3 text-center p-3 border border-2">
            <img src="img/logo/Rajdeep.jpg" width="80%" />
          </div>
          <div className="col-md-3 text-center p-3 border border-2">
            <img src="img/logo/tedra.jpg" width="50%" />
          </div>
          <div className="col-md-3 text-center p-3 border border-2">
            <img src="img/logo/ultra.png" width="50%" />
          </div>
          <div className="col-md-3 text-center p-3 border border-2">
            <img src="img/logo/United.jpg" width="50%" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Industries;
