import React from "react";

import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    alert("Form Submitted Successfully.");

    emailjs
      .sendForm(
        "service_x0qp7zc",
        "template_2s4894j",
        form.current,
        "tmTWW-hXcDhbB5j6e"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <>
      <div className="container pt-5 mt-5">
        <h1 className="font-weight-bold text-primary">CONTACT</h1>
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Contact
            </li>
          </ol>
        </nav>
      </div>
      <div class="container text-center">
        <img src="img/contact4.jpg" class="img-fluid " alt="contact" />
      </div>
      <div className="container">
        <div className="row py-5 ">
          <div className="col-md-7">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.6025344466575!2d73.92695331420452!3d18.54685507321204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c17b45a2d07d%3A0xf604996a66691d5d!2sBollywood%20E-Square!5e0!3m2!1sen!2sin!4v1679994218099!5m2!1sen!2sin"
              width="600"
              height="300"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="col-md-3">
            <p>
              <i class="fa fa-map-marker" aria-hidden="true"></i>
              Naudeviyon Homes, Flat: 103, Behind Bollywood E-square Theater,
              Mahadevnagar, Vadgoansheri, Pune – 14.
            </p>
          </div>
          <div className="col-md-2">
            <p> info@digiopentechnologies.com</p>
          </div>
        </div>
      </div>
      <h1 className=" text-center pt-3 fw-bold">Drop Us A Line</h1>
      <p className="text-center fs-4">Share Your Requirements</p>
      <p className="text-center fs-4 container">
        We appreciate your interest in DigiOpen Technologies. Please take an
        appropriate action from the available options or fill the form below &
        we will get back to you at the earliest.
      </p>
      <div className="container mb-3">
        <form ref={form} onSubmit={sendEmail} className="p-3  shadow ">
          <div className="row g-3 py-3">
            <div class="col-md-6">
              <label for="inputEmail4" class="form-label">
                Name
              </label>
              <input
                type="name"
                name="name"
                class="form-control"
                id="inputEmail4"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="inputPassword4" class="form-label">
                Organization
              </label>
              <input
                type="organization"
                name="organization"
                class="form-control"
                id="organization"
                required
              />
            </div>
          </div>
          <div className="row g-3 py-3">
            <div class="col-md-6">
              <label for="inputEmail4" class="form-label">
                Work Email
              </label>
              <input
                type="email"
                name="email"
                class="form-control"
                id="inputEmail4"
                required
              />
            </div>
            <div class="col-md-6">
              <label for="inputPassword4" class="form-label">
                Phone No.
              </label>
              <input
                type="phone"
                name="mobile"
                class="form-control"
                id="phone"
                required
              />
            </div>
          </div>
          <div class=" py-3">
            <label for="floatingTextarea" class="form-label">
              Give us a brief note of your requirements
            </label>
            <textarea
              class="form-control"
              type="brief"
              name="brief"
              id="requirement"
              rows="3"
              required
            ></textarea>
          </div>
          <div className=" ">
            <button className="btn btn-outline-warning text-dark ">
              Reset
            </button>
            <button className="btn btn-warning pl-2 text-white">Submit</button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Contact;
