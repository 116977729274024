import React from "react";
import { Link } from "react-router-dom";

const ProductDevelopment = () => {
  return (
    <>
      <div className="container">
        <h1 className="pt-5 mt-5 text-danger">Product Development</h1>
        <nav>
          <ol class="breadcrumb ">
            <li class="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Product Development
            </li>
          </ol>
        </nav>
        <h3 className="text-primary">Services Offered</h3>
        <p className="fs-6">
          DigiOpen’s product development service is for clients who wish to
          build products by providing the product specifications and who would
          like a specialist technology company to architect, develop, maintain
          and support it. DigiOpen has built products for different clients in
          India
        </p>
        <div className="row py-5">
          <div className="col-md-6">
            <img src="img/productidea.jpg" width="90%" height="70%" />
          </div>
          <div className="col-md-6">
            <h3 className="font-weight-bold text-primary">
              Idea to Implementation
            </h3>
            <p className="fs-6">
              This offering includes the complete range of software engineering
              services from building product road-map and architecture,
              prototyping , initial pilot development through to evolution of
              the product through an agreed release plan and maintenance and
              support for the product. One of the options here is to offer the
              customer a BOT (build-operate-transfer ) model where the team
              could be transferred to the customer if they so require once the
              product has matured or when the business has scaled up to the
              point that it would like to invest in an IT team.
            </p>
          </div>
        </div>
        <div className="row py-5">
          <div className="col-md-6">
            <img src="img/productmaintenance.jpg" width="90%" height="70%" />
          </div>
          <div className="col-md-6">
            <h3 className="font-weight-bold text-primary">
              Product Maintenance
            </h3>
            <p className="fs-6">
              This option is for customers who wish to outsource the maintenance
              of internally products after the development phase. This is
              helpful in situations where the customer is looking to reduce
              maintenance costs with overseas teams. A structured transition
              phase where the team acquires product knowledge and defines the
              support/maintenance and change management processes would kick off
              the engagement. The subsequent maintenance phase could involve a
              dedicated team or a shared team with a minimum committed annual
              effort.
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-6">
            <img src="img/productarchitecture.jpg" width="90%" height="70%" />
          </div>
          <div className="col-md-6">
            <h3 className="font-weight-bold text-primary">
              Product re-architecture
            </h3>
            <p className="fs-6">
              Product owners with successful products sometimes see the
              requirement for a new build-from-scratch replacement version to
              replace the current in-use product. DigiOpen would then understand
              the new version’s drivers and then in collaboration with the
              client team define the product’s new architecture, developing
              prototypes where early validation is required. Where required the
              team can also reverse engineer requirements from the legacy
              version or work with customer provided requirements to design and
              implement the new product version
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDevelopment;
