import React from "react";

const MobileDevelopment = () => {
  return (
    <>
      <div className="container">
        <h1 className="mt-5 text-primary pt-5">MOBILE APP DEVELOPMENT</h1>
        <p className="fs-6">
          we've been performing the role of the remote app development team for
          companies who want to generate new or increase their existing business
          using mobile platforms. Android apps we develop put our clients'
          brands at their customer's fingertips. We've built more than 20
          applications and we'd be happy to team up with you to implement your
          project.
        </p>
        <h4 className="text-danger">ANDROID APP DEVELOPMENT</h4>
        <p className="fs-6">
          With our deep understanding of the material design and excellent
          knowledge of everything Android-related, we're one of those engineers
          you would want to be part of your team.{" "}
        </p>
        <p className="fs-6">
          With so many Android users in the world, building an application for
          Android smartphones and tablets is almost a must for online
          entrepreneurs. We're fluent in Java and React can help you build the
          desired product that will set you apart from the competition.
        </p>
      </div>
      <div></div>
      <br />
      <div></div>
      <br />
      <div></div>
    </>
  );
};

export default MobileDevelopment;
