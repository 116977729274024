import { useState } from "react";
import { useNavigate } from "react-router-dom";

function EditForm() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const handleDescChange = (e) => {
    setDesc(e.target.value);
  };

  const handleEdit = () => {
    console.log({ title, desc, index: localStorage.getItem("editIndex") });
    let blogs =
      localStorage.getItem("blogs") && localStorage.getItem("blogs").length > 0
        ? JSON.parse(localStorage.getItem("blogs"))
        : [];

    const _blogs = blogs.map((blog, blogInIndex) => {
      if (blogInIndex === localStorage.getItem("editIndex")) {
        return { title, desc };
      } else {
        return blog;
      }
    });
    console.log(_blogs);
    localStorage.setItem("blogs", JSON.stringify(_blogs));
    navigate("/form");
  };

  return (
    <>
      <div className="mt-5 pt-5">
        <h1 className="text-center"> Edit BLOG </h1>
        <form className="card container py-5 px-5">
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              Title
            </label>
            <input
              value={title}
              onChange={(e) => handleTitleChange(e)}
              class="form-control"
              label="Title"
            />
          </div>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">
              Description
            </label>
            <input
              value={desc}
              onChange={(e) => handleDescChange(e)}
              class="form-control"
              label="Description"
            />
          </div>

          <button onClick={handleEdit} type="submit" class="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
    </>
  );
}

export default EditForm;
