import Navbar from "./Header/Navbar";
import Carrer from "./Pages/Carrer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import BusinessConsulting from "./Services/BusinessConsulting";
import ErpConsulting from "./Services/ErpConsulting";
import ERPEmplimentation from "./Services/ERPEmplimentation";
import MobileDevelopment from "./Services/MobileDevelopment";
import ProductDevelopment from "./Services/ProductDevelopment";
import Home from "./Pages/Home";
import Industries from "./Pages/Industries";
import Footer from "./Pages/Footer";
import Login from "./Pages/Login";
import ProductPage from "./Pages/ProductPage";
import FormData from "./Pages/FormData";
import EditForm from "./Pages/EditForm";
function App() {
  return (
    <>
      <div>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/carrer" element={<Carrer />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/industries" element={<Industries />} />
            <Route path="/consulting" element={<ErpConsulting />} />
            <Route path="/erpemplimentation" element={<ERPEmplimentation />} />
            <Route path="/appdevelopment" element={<MobileDevelopment />} />
            <Route
              path="/productdevelopment"
              element={<ProductDevelopment />}
            />
            <Route
              path="/businessconsulting"
              element={<BusinessConsulting />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="/product" element={<ProductPage />} />
            <Route path="/form" element={<FormData />} />
            <Route path="/edit" element={<EditForm />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
