import React from "react";

const Home = () => {
  return (
    <>
      <div></div>
      <br />
      <div className="container mt-5 pt-5">
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              class="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div className="row">
                <div className="col-md-6">
                  <img
                    src="img/transform.jpg"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div className="col-md-6 pt-5">
                  <h1 className="text-danger fw-bold">TRANSFORM </h1>
                  <h3 className="text-success">
                    Ideas into world class product
                  </h3>
                  <p className="fs-4">
                    DigiOpen Technologies -We can help you to realise your
                    product vision
                  </p>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div className="row">
                <div className="col-md-6">
                  <img src="img/automate.jpg" class="d-block w-100" alt="..." />
                </div>
                <div className="col-md-6 pt-5">
                  <h1 className="text-danger fw-bold"># OF DAYS</h1>
                  <h3 className="text-success">To automate your business</h3>
                  <p className="fs-4">
                    The world’s most popular opensource ERP
                  </p>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div className="row">
                <div className="col-md-6">
                  <img
                    src="img/businesstools.jpg"
                    class="d-block w-100"
                    alt="..."
                  />
                </div>
                <div className="col-md-6 pt-5">
                  <h1 className="text-danger fw-bold">EASY BUSINESS TOOLS </h1>
                  <h3 className="text-success ">For faster Growth</h3>
                  <p className="fs-4">Have an ever growing list of Modules</p>
                </div>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>

        <br />
        <div></div>
        <br />
        <div></div>
        <br />
        <div></div>
        <br />
        <div></div>
        <div></div>
        <br />
        <div></div>
        <br />
        <div></div>
        <div></div>
        <br />
        <div></div>
        <br />
        <div></div>
      </div>
    </>
  );
};

export default Home;
