import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

function FormData() {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const blogs = localStorage.getItem("blogs");
    setBlogs(JSON.parse(blogs));
  }, [blogs]);

  const handleDelete = (blogOutIndex) => {
    const _blogs = blogs.filter((blog, blogInIndex) => {
      if (blogInIndex !== blogOutIndex) {
        return blog;
      }
    });
    console.log(_blogs);
    setBlogs(_blogs);
    localStorage.setItem("blogs", JSON.stringify(_blogs));
  };

  const handleEdit = (blogIndex) => {
    localStorage.setItem("editIndex", blogIndex);
    navigate("/edit");
  };

  return (
    <>
      <div className="mt-5 pt-5 container">
        <Link to="/product"> Add Product</Link>
        <table class="table border border-1">
          <thead>
            <tr>
              <th scope="col">Title</th>
              <th scope="col">Description</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {blogs && blogs.length > 0
              ? blogs.map((blog, blogIndex) => {
                  return (
                    <tr>
                      <td>{blog.title}</td>
                      <td>{blog.desc}</td>

                      <td>
                        <button
                          onClick={() => handleEdit(blogIndex)}
                          className="btn btn-primary"
                        >
                          Edit
                        </button>
                      </td>
                      <td>
                        <button
                          onClick={() => handleDelete(blogIndex)}
                          className="btn btn-danger"
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  );
                })
              : "No Data found"}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default FormData;
