import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <>
      <div class="navbar navbar-expand-lg fixed-top navbar-white  bg-white shadow ">
        <div class="container ">
          <Link class="nav-item  " to="/">
            <img
              src="img/CompanyLogo3.png"
              className="fs-1 color-primary"
              width={170}
            />
            {/* <p className="nav-link fs-6">Digitalisation in Opensource</p> */}
          </Link>

          <button
            class="navbar-toggler btn btn-outline-dark"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon bg-dark"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav ms-auto ">
              <li class="nav-item">
                <Link class="nav-link active  mx-2" aria-current="page" to="/">
                  Home
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link mx-2" to="about">
                  About Us
                </Link>
              </li>
              <li class="nav-item">
                <Link class="nav-link mx-2" to="industries">
                  Industries
                </Link>
              </li>

              <li class="nav-item">
                <Link class="nav-link mx-2" to="carrer">
                  Career
                </Link>
              </li>
              <li class="nav-item dropdown">
                <Link
                  class="nav-link dropdown-toggle mx-2"
                  to="#"
                  id="navbarDropdownMenuLink"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Services
                </Link>
                <ul
                  class="dropdown-menu"
                  aria-labelledby="navbarDropdownMenuLink"
                >
                  <li>
                    <Link class="dropdown-item" to="businessconsulting">
                      Business Consulting
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="consulting">
                      ERP Consulting
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="erpemplimentation">
                      ERP Emplimentation
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="appdevelopment">
                      Mobile Development
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="productdevelopment">
                      Product Development
                    </Link>
                  </li>
                </ul>
              </li>
              <li class="nav-item">
                <Link class="nav-link mx-2" to="contact">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
