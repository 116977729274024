import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Add() {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const handleDescChange = (e) => {
    setDesc(e.target.value);
  };

  const handleSubmit = () => {
    console.log({ title, desc });

    const _blogs =
      localStorage.getItem("blogs") && localStorage.getItem("blogs").length > 0
        ? JSON.parse(localStorage.getItem("blogs"))
        : [];

    localStorage.setItem("blogs", JSON.stringify([..._blogs, { title, desc }]));

    navigate("/form");
  };

  return (
    <>
      <div className="mt-5 pt-5 container">
        <form onSubmit={handleSubmit} className="card py-5 px-5">
          <div class="mb-3">
            <label for="exampleInputEmail1" class="form-label">
              Title
            </label>
            <input
              value={title}
              class="form-control"
              onChange={(e) => handleTitleChange(e)}
              label="Title"
            />
          </div>
          <div class="mb-3">
            <label for="exampleInputPassword1" class="form-label">
              Description
            </label>
            <input
              value={desc}
              class="form-control"
              onChange={(e) => handleDescChange(e)}
              label="Description"
            />
          </div>

          <button type="submit" class="btn btn-primary">
            Submit
          </button>
        </form>
      </div>
    </>
  );
}

export default Add;
