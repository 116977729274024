import React from "react";

const About = () => {
  return (
    <>
      <div className="container">
        <div class=" row pt-5 mt-5">
          <div className="col-md-6">
            <img
              src="img/about.jpg"
              alt="Snow"
              className="img-fluid"
              width="500px"
            />
          </div>
          <div class=" col-md-6 ">
            <h1 className="text-danger">
              Technologies that make Business Easy
            </h1>
          </div>
        </div>
        <div>
          <h1 className="fw-bold text-primary pt-3">Who We Are</h1>
          <p className="fs-6">
            DigiOpen Technologies incorporated in the year 2018 is brainchild of
            team of industry experts. The core team members of DigiOpen
            Technologies are having more than 15+ years of ERP experience with
            more than 20+ implementation experiences under their belt. Till now
            there was a myth that ERP’s are costly but with DigiOpen
            Technologies the whole dimension will change as we present robust
            and feature rich solutions at a very reasonable pocket friendly
            cost. Our mission is to provide ERP to everyone who want to manage
            their entire business processes with a single solution.
          </p>
        </div>

        <div className="row">
          <div className="col-md-7">
            <img src="img/aboutus.jpg" width="500px" className="img-fluid" />
          </div>
          <div className="col-md-5">
            <div className="py-1 ">
              <div
                class="btn btn-primary w-75"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Quality Policy
              </div>
            </div>
            <div class="collapse" id="collapseExample">
              <div class="card card-body">
                At Digiopen Technologies, We are committed to creating value for
                our clients today and into the future by constantly renewing our
                knowledge, skills and techniques to innovate new ways to serve
                customers and enable the human possibilities of technology
              </div>
            </div>
            <div className="py-1">
              <div
                class="btn btn-primary  w-75"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample1"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Our Vision
              </div>
            </div>
            <div class="collapse" id="collapseExample1">
              <div class="card card-body">
                A world in which every business takes advantage of
                digitalisation.
              </div>
            </div>
            <div className="py-1">
              <div
                class="btn btn-primary  w-75"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample2"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Our Mission
              </div>
            </div>
            <div class="collapse" id="collapseExample2">
              <div class="card card-body">
                To help our clients adapt new technologies that results in
                consistent and substantial improvements in their business.
              </div>
            </div>
            <div className="py-1">
              <div
                class="btn btn-primary  w-75"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseExample3"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Our Values
              </div>
            </div>

            <div class="collapse" id="collapseExample3">
              <div class="card card-body">
                <ul>
                  <li>Preserve client confidences</li>
                  <li>Maintain an independent perspective</li>
                  <li>Put client interests ahead of the firm’s</li>
                  <li>Observe high ethical standards</li>
                  <li>Manage client and firm resources cost-effectively</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
