import React from "react";
import { Link } from "react-router-dom";

const ERPEmplimentation = () => {
  return (
    <>
      <div className="container">
        <h1 className="mt-5 pt-5 text-danger ">ERP IMPLEMENTATION</h1>
        <div className="row">
          <div className="col-md-6 mt-5">
            <nav>
              <ol class="breadcrumb ">
                <li class="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  ERP Implementation
                </li>
              </ol>
            </nav>
          </div>
          <div className="col-md-6">
            <img
              src="img/erp.jpg"
              alt="services Business"
              width="90%"
              height="70%"
            />
          </div>
        </div>
        <h1 className="text-primary">Services Offered</h1>
        <div className="row">
          <div className="col-md-6">
            <img
              src="img/comperp.jpg"
              width="100%"
              height="60%"
              className="shadow"
            />
          </div>
          <div className="col-md-6">
            <h4 className="font-weight-bold text-primary">
              Comprehensive ERP Implementation
            </h4>
            <p className="fs-6">
              This service is primarily focused on SMEs. Here our business
              consultants support the implementation, starting with
              understanding the drivers for the ERP , defining the goals and
              then project manage the solution definition, implementation , data
              migration and training. Two important elements in this approach
              are
            </p>

            <ul className="pl-5">
              <li className="fs-6">
                that the process engages the client through the implementation
                with demo and feedback requests
              </li>
              <li className="fs-6">
                the lead consultants works with the “project champion”,
                previously identified by the client, and empowers him to be the
                catalyst for change within the customer team and source of
                feedback from the user community.
              </li>
            </ul>
            <p className="fs-6">
              Post implementation, the engagement continues with DigiOpen
              providing support , solution maintenance etc.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 pt-5">
            <img
              src="img/enterprises.jpg"
              width="100%"
              height="70%"
              className="shadow"
            />
          </div>
          <div className="col-md-6 pt-5">
            <h3 className="fw-bold text-primary">Enterprise Engagements</h3>
            <p className="fs-6">
              Larger enterprises with dedicated IT teams, engage DigiOpen in all
              or some of these activities that form a part of their business
              system implementation process.
            </p>
            <ul className="pl-5 fs-6">
              <li>
                Perform/Support product evaluation and participate in the
                comparison of choices
              </li>
              <li>
                Prepare a blue-print defining the functional solution against
                requirements provided by them
              </li>
              <li>
                Execute the product implementation project. Implementation
                process is iterative, with weekly/fortnightly releases to a
                customer test environment, where the features are shown and
                adjusted for customer feedback.
              </li>
              <li>
                Execute sub-projects related to data migration and integration
                either entirely or in collaboration with the customer IT team
              </li>
              <li>
                Skill of members of the customer team to enhance the solution
                and also in providing level 1 support internally for the other
                employees
              </li>
            </ul>
          </div>
        </div>
        <div className="row py-4">
          <div className="col-md-6">
            <img
              src="img/maintain.jpg"
              width="100%"
              height="70%"
              className="shadow"
            />
          </div>
          <div className="col-md-6">
            <h3 className="fw-bold text-primary">Maintenance and Support</h3>
            <p className="fs-6">
              This is for customers of large implementations who need an expert
              team to take over and maintain their implementation in a cost
              effective way. This includes supporting for the production
              implementation, performing upgrades to the core product and
              executing projects to extend the product usage or integrate it
              with other systems. Support services generally include functional
              and technical support on the product but can also include
              infrastructure support for servers which can either be in a data
              centre or on cloud.
            </p>
          </div>
        </div>
        <div className="row py-4">
          <div className="col-md-6">
            <img
              src="img/projectimplement.jpg"
              width="100%"
              height="70%"
              className="shadow"
            />
          </div>
          <div className="col-md-6">
            <h3 className="fw-bold text-primary">Project Implementation</h3>
            <p>
              This is for customers who have an existing ERP implementation and
              want to execute specific projects to enhance it’s capabilities.
              Some projects that Digiopen has executed in the past include
              building mobile front-end solutions for the ERP, developing a
              data-warehouse with data from the ERP and other systems or
              providing an integration with other systems. DigiOpen offers fixed
              price as well as T&M based project proposals for such engagements
              and provides free one year warranty support for bug fixes.
            </p>
          </div>
        </div>
        <div className="row py-4">
          <div className="col-md-6">
            <img
              src="img/implementrescue.jpg"
              width="100%"
              height="70%"
              className="shadow"
            />
          </div>
          <div className="col-md-6">
            <h3 className="fw-bold text-primary">Implementation Rescue</h3>
            <p>
              DigiOpen also execute rescue engagements, where a customer
              implementing the ERP finds it stalled or not meeting business
              expectations. A typical rescue engagement starts with an audit
              based on which a situation assessment and recommendations are
              provided. The next stage is to provide a plan that provides
              predictability of delivery to the customer, while also harvesting
              existing partially implemented features to the extent possible and
              execute the same as a project.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ERPEmplimentation;
