import React from "react";

const ErpConsulting = () => {
  return (
    <>
      <div className="container">
        <h1 className="mt-5 pt-5  text-center font-weight-bold">
          ERP CONSULTING WITH <span className="text-primary">DigiOpen</span>
        </h1>
        <p className="fs-6">
          DigiOpen has a proven track record of end-to-end ERP customization
          across diverse industries. We offer our clients a ‘Risk free
          Customization and Upgrade’ that enables them to evaluate the right ERP
          solution, design a business blueprint aligned with business goals and
          implement a solution that extracts maximum value from their workforce.
          Our on-time, on-budget delivery of projects with highest quality has
          been acclaimed by the industry globally. We have developed a wide
          range of in-house frameworks and accelerators that power our
          implementation methodology and leverage industry best practices,
          templates and a global delivery to ensure that implementations are
          delivered with minimum risk at a lowest possible cost.
        </p>
      </div>
      <div></div>
      <br />
      <div></div>
      <br />
      <div></div>
      <br />
      <div></div>
      <br />
      <div></div>
      <br />
      <div></div>
    </>
  );
};

export default ErpConsulting;
